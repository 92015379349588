import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e0589fc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-field-label"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "placeholder", "type", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-field", { 'text-field-error': $setup.hasValidationError }])
  }, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["text-field-show-pass", { show: $setup.showPass, hide: !$setup.showPass }]),
      onClick: $setup.onShowPass
    }, null, 2),
    _createElementVNode("label", { for: $props.placeholder }, null, 8, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      class: "text-field-input",
      id: $props.placeholder + '-' + Math.random(),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localModelValue) = $event)),
      placeholder: $props.placeholder,
      type: $setup.showPass ? 'text' : $props.type,
      disabled: $props.disabled,
      onInput: $setup.onInput
    }, null, 40, _hoisted_3), [
      [_vModelDynamic, $setup.localModelValue]
    ]),
    ($props.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 2))
}