import axiosClient from "./index";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import { IProfile } from "@/types/entities/IProfile";
import { IUploadFileResponse } from "@/types/api/IUploadFileResonse";
import { ICoursesListParams } from "@/types/api/ICoursesListParams";
import { IProfileDashboardResponse } from "@/types/api/IProfileDashboardResponse";
import { IListResponseBody } from "@/types/api/IListResponseBody";
import { ICoursePreview } from "@/types/entities/ICoursePreview";

export default {
  updateProfile(
    payload: Partial<IProfile>
  ): Promise<ApiClientResponse<IProfile>> {
    return axiosClient.patch("profile", payload);
  },
  uploadFile(
    payload: FormData
  ): Promise<ApiClientResponse<IUploadFileResponse>> {
    return axiosClient.post("file", payload);
  },
  getProfileDashboard(): Promise<ApiClientResponse<IProfileDashboardResponse>> {
    return axiosClient.get("profile");
  },
  getMyCourses(
    params: ICoursesListParams
  ): Promise<ApiClientResponse<IListResponseBody<ICoursePreview>>> {
    return axiosClient.get("profile/courses", { params });
  },
};
