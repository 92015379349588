import dayjs from "dayjs";
import en from "dayjs/locale/en";
import duration from "dayjs/plugin/duration";

export default function useDayjs() {
  dayjs.locale({
    ...en,
  });
  dayjs.extend(duration);

  const formatDate = (date: string, format: string): string => {
    return dayjs(date).format(format);
  };

  return { dayjs, formatDate };
}
