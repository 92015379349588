import { ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useUserStore } from "@/stores/user";
import routesName from "@/utils/constants/routesName";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  const isAuthorized = ref<boolean>(!!userStore.accessToken);
  if (isAuthorized.value) {
    if (
      !userStore.userData?.onboarded_at &&
      to.name !== routesName.profileOnboarding
    ) {
      next({
        name: routesName.profileOnboarding,
      });
      return;
    }
    if (to.meta.notAuthorized) {
      next({
        name: routesName.landing,
      });
      return;
    }
  } else {
    if (to.meta.protected) {
      next({
        name: routesName.login,
        query: { redirect: to.path },
      });
    }
  }
  next();
});

export default router;
