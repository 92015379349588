import { defineStore } from "pinia";
import { ref } from "vue";
import { IProfile, type IProfileSection } from "@/types/entities/IProfile";
import router from "@/router";
import routesName from "@/utils/constants/routesName";
import usersApi from "@/services/api/usersApi";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import type { IFile } from "@/types/entities/IFile";

export const useUserStore = defineStore(
  "user",
  () => {
    const accessToken = ref<string>("");
    const refreshToken = ref<string>("");
    const userData = ref<IProfile | null>(null);

    const setTokens = (payload: {
      accessToken: string;
      refreshToken: string;
    }): void => {
      accessToken.value = payload.accessToken;
      refreshToken.value = payload.refreshToken;
    };

    const setUserProfile = (profile: IProfile): void => {
      userData.value = profile;
    };

    const clear = (): void => {
      accessToken.value = "";
      refreshToken.value = "";
      userData.value = null;
      router.push({ name: routesName.landing });
    };

    const updateProfile = (payload: {
      email: string;
      first_name: string;
      last_name: string;
      avatar: IFile;
      profile: IProfileSection;
    }): Promise<ApiClientResponse<IProfile>> => {
      return usersApi.updateProfile(payload).then((res) => {
        setUserProfile(res.data.data);
        return res;
      });
    };

    return {
      accessToken,
      refreshToken,
      userData,
      setTokens,
      setUserProfile,
      clear,
      updateProfile,
    };
  },
  {
    persist: true,
  }
);
