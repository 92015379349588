import type { IUserMenuOption } from "@/types/entities/IUserMenuOption";
import routesName from "@/utils/constants/routesName";

const userMenuOptions: IUserMenuOption[] = [
  {
    iconName: "tabler-icon-layout-dashboard",
    label: "Dashboard",
    routeName: routesName.dashboard,
  },
  {
    iconName: "tabler-icon-school",
    label: "My Courses",
    routeName: routesName.myCourses,
  },
  {
    iconName: "tabler-icon-certificate",
    label: "My Certificates",
    routeName: routesName.myCertificates,
  },
  {
    iconName: "tabler-icon-user-circle",
    label: "My Profile",
    routeName: routesName.myProfile,
  },
  {
    iconName: "tabler-icon-settings",
    label: "My Settings",
    routeName: routesName.mySettings,
  },
  {
    iconName: "tabler-icon-logout",
    label: "Logout",
    action: "onLogout",
  },
];

export default userMenuOptions;
