import axiosClient from "./index";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import { ILoginResponseBody } from "@/types/api/ILoginResponseBody";
import { AxiosResponse } from "axios";
import { IProfile } from "@/types/entities/IProfile";

export default {
  login(payload: {
    password: string;
    login: string;
    app: "admin" | "user";
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/login", payload);
  },
  register(payload: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/register", payload);
  },
  refreshToken(payload: {
    access_token: string;
    refresh_token: string;
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/refresh", payload);
  },
  logout(): Promise<AxiosResponse> {
    return axiosClient.post("auth/logout");
  },
  getProfile(): Promise<ApiClientResponse<IProfile>> {
    return axiosClient.get("auth/profile");
  },
  updateProfile(
    payload: Partial<IProfile>
  ): Promise<ApiClientResponse<IProfile>> {
    return axiosClient.patch("auth/profile", payload);
  },
  resetPassword(payload: {
    token: string;
    password: string;
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/reset-password", payload);
  },
  resetPasswordInit(payload: {
    email: string;
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/reset-password-init", payload);
  },
  sendConfirmEmail(payload: {
    email: string;
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/send-confirm-email", payload);
  },
  confirmAccount(payload: {
    token: string;
  }): Promise<ApiClientResponse<ILoginResponseBody>> {
    return axiosClient.post("auth/confirm", payload);
  },
  changePassword(payload: {
    old_password: string;
    new_password: string;
  }): Promise<ApiClientResponse<IProfile>> {
    return axiosClient.post("auth/change-password", payload);
  },
};
